define('tevins/router', ['exports', 'ember', 'tevins/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    metrics: _ember.default.inject.service(),
    rootURL: _environment.default.rootURL,

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      _ember.default.run.scheduleOnce('afterRender', this, function () {
        _ember.default.get(_this, 'metrics').trackPage('GoogleAnalytics');
      });
    }
  });

  Router.map(function () {
    this.route('dashboard', { path: '/dashboard' }, function () {
      this.route('users', { resetNamespace: true });
      this.route('inspections', { resetNamespace: true });
      this.route('subscriptions', { resetNamespace: true }, function () {
        this.route('show', { path: ':subscription_id' });
      });
      this.route('invoice', { resetNamespace: true, path: '/invoices/:invoice_id' });
      this.route('account', { resetNamespace: true });
    });
    this.route('login');
    this.route('password-reset');
  });

  exports.default = Router;
});