define('tevins/routes/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    beforeModel: function beforeModel(transition) {
      this.controllerFor('index').set('loginModal', transition.queryParams.loginModal);
      this.controllerFor('index').set('passwordResetModal', transition.queryParams.passwordResetModal);
    }
  });
});