define('tevins/routes/subscriptions/show', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_authenticatedRouteMixin.default, {
    session: _ember.default.inject.service(),
    token: _ember.default.inject.service(),
    sessionUser: _ember.default.inject.service('session-user'),

    model: function model(params) {
      return this.store.findRecord('subscription-plan', params.subscription_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var self = this;

      this.get('sessionUser').loadCurrentUser().then(function () {
        controller.set('user', self.get('sessionUser.user'));
      });
    },


    renderTemplate: function renderTemplate() {
      this.render('subscriptions.show', {
        into: 'subscriptions'
      });
    }
  });
});