define('tevins/controllers/subscriptions/show', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    vat: _ember.default.computed('model.price', function () {
      var price = this.get('model.price');

      return price * 0.21;
    }),

    fullPrice: _ember.default.computed('model.price', function () {
      var price = this.get('model.price');

      return price * 1.21;
    }),

    actions: {
      orderSubscriptionPlan: function orderSubscriptionPlan(subscriptionPlanNext) {
        var change = this.store.createRecord('subscription-plan-change', { subscriptionPlanNext: subscriptionPlanNext });
        change.save().then(function (data) {
          window.location = data.get('payment').get('paymentUrl');
        });
      }
    }
  });
});