define('tevins/controllers/account', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    userShow: true,
    companyShow: true,
    sortedCompanyPlans: _ember.default.computed.sort('company.companyPlans', 'sortDefinition'),
    sortDefinition: ['startAt'],

    usage: _ember.default.computed('user.company.companyPlan', function () {
      var companyPlan = this.get('user.company.companyPlan');

      if (companyPlan) {
        return Math.round(companyPlan.get('inspectionsCurrent') / companyPlan.get('inspectionsMax') * 100);
      }
    }),

    actions: {
      update: function update(resource) {
        var model = this.get(resource);
        var self = this;

        this.store.findRecord(resource, model.get('id')).then(function (record) {
          record.save().then(function () {
            self.send('resetErrors', resource);
            self.send('toggleEdit', resource);
          }).catch(function (error) {
            self.send('resetErrors', resource);

            error['errors'].forEach(function (key) {
              var selector = key.source.pointer.split("/").pop();
              var message = key.title;
              _ember.default.$('.resource-' + resource + ' #' + selector).closest('.field').addClass('field-with-error').append('<span class="hint--left hint--rounded" aria-label="' + message + '"><i class="fa fa-question-circle"></i></span>');
            });
          });
        });
      },
      didUpdatePercentage: function didUpdatePercentage(percentage) {
        this.set('percentage', percentage);
      },
      toggleEdit: function toggleEdit(resource) {
        this.toggleProperty(resource + 'Show');
      },
      resetErrors: function resetErrors(resource) {
        _ember.default.$('.resource-' + resource + ' .field-with-error').removeClass('field-with-error').find('span.hint--left').hide();
      }
    }
  });
});