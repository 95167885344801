define('tevins/authenticators/custom', ['exports', 'ember', 'ember-simple-auth/authenticators/base', 'tevins/config/environment'], function (exports, _ember, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Promise = _ember.default.RSVP.Promise,
      run = _ember.default.run,
      $ = _ember.default.$,
      isEmpty = _ember.default.isEmpty;
  exports.default = _base.default.extend({
    serverTokenEndpoint: _environment.default.host + '/ui/authentication/sign-in',

    restore: function restore(data) {
      if (!isEmpty(data['token'])) {
        return Promise.resolve(data);
      } else {
        return Promise.reject();
      }
    },
    authenticate: function authenticate(email, password) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var data = JSON.stringify({ email: email, password: password });
        return _this.makeRequest(data, 'POST').then(function (response) {
          return run(null, resolve, response);
        }, function (xhr) {
          return run(null, reject, xhr.responseJSON || xhr.responseText);
        });
      });
    },
    makeRequest: function makeRequest(data, type, options) {
      var serverTokenEndpoint = this.get('serverTokenEndpoint');
      var requestOptions = $.extend({}, {
        url: serverTokenEndpoint,
        type: type,
        dataType: 'json',
        contentType: 'application/json',
        data: data,
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Accept', 'application/json');
        }
      }, options || {});

      return $.ajax(requestOptions);
    }
  });
});