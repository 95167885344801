define('tevins/services/token', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Service.extend({
    session: _ember.default.inject.service(),

    data: _ember.default.computed('session.session.content.authenticated', function () {
      var authenticator = _ember.default.getOwner(this).lookup('authenticator:jwt');
      var session = this.get('session.session.content.authenticated');
      var tokenData = {};

      if (session && Object.keys(session).length > 0) {
        tokenData = authenticator.getTokenData(session.token);
      }

      return tokenData;
    })
  });
});