define('tevins/routes/account', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'tevins/config/environment'], function (exports, _ember, _authenticatedRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_authenticatedRouteMixin.default, {
    session: _ember.default.inject.service(),
    token: _ember.default.inject.service(),
    sessionUser: _ember.default.inject.service('session-user'),

    beforeModel: function beforeModel(transition) {
      this.controllerFor('account').set('paymentFinished', transition.queryParams.paymentFinished);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var self = this;

      this.get('sessionUser').loadCurrentUser().then(function () {
        var user = self.get('sessionUser.user');

        controller.set('user', user);
        controller.set('company', user.get('company'));
        controller.set('uploadUrl', _environment.default.host + '/' + _environment.default.apiNamespace + '/companies/' + user.company.id + '/upload');
      });
    },


    renderTemplate: function renderTemplate() {
      this.render('account', {
        into: 'dashboard'
      });
    }
  });
});