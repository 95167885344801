define('tevins/controllers/inspections', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    sessionUser: _ember.default.inject.service('session-user'),

    modelName: 'inspection',
    defaultFields: {
      state: 'open',
      address: '',
      agent: '',
      inspection_type: 'begin',
      occupant_type: 'buy',
      building_type: 'business',
      inspect_at: ''
    },

    modalNew: false,
    spinner: false,

    modelSorting: ['insertedAt:desc'],
    modelSorted: _ember.default.computed.sort('model', 'modelSorting'),

    inspectionTypes: ['begin', 'tussen', 'eind'],

    occupantTypes: [{
      "id": 'buy',
      "name": 'koop'
    }, {
      "id": 'rent',
      "name": 'huur'
    }],

    buildingTypes: [{
      "id": 'residence',
      "name": 'particulier'
    }, {
      "id": 'business',
      "name": 'zakelijk'
    }],

    states: [{
      "id": 'open',
      "name": 'open'
    }, {
      "id": 'concept',
      "name": 'concept'
    }, {
      "id": 'closed',
      "name": 'gesloten'
    }],

    actions: {
      showModalNew: function showModalNew() {
        this.set('modalNew', true);
        this.set('newModel', this.store.createRecord(this.get('modelName'), this.get('defaultFields')));
      },
      closeModalNew: function closeModalNew() {
        this.set('modalNew', false);

        var newModel = this.get('newModel');
        newModel.rollbackAttributes();
      },
      closeModalOnboarding: function closeModalOnboarding() {
        this.set('modalOnboarding', false);
        var self = this;

        this.get('sessionUser').loadCurrentUser().then(function () {
          var user = self.get('sessionUser.user');
          user.set('modalOnboarding', false);
        });
      },
      create: function create() {
        this.set('spinner', true);
        var newModel = this.get('newModel');
        var self = this;

        newModel.set('inspect_at', new Date(newModel.get('inspect_at')));

        newModel.save().then(function () {
          self.set('spinner', false);
          self.set('modalNew', false);
          self.send('resetErrors');
        }).catch(function (error) {
          self.send('resetErrors');
          self.set('spinner', false);

          error['errors'].forEach(function (key) {
            var selector = key.source.pointer.split("/").pop();
            var message = key.title;
            _ember.default.$('#' + selector).closest('.field').addClass('field-with-error').append('<span class="hint--left hint--rounded" aria-label="' + message + '"><i class="fa fa-question-circle"></i></span>');
          });
        });
      },
      resetErrors: function resetErrors() {
        _ember.default.$('.field-with-error').removeClass('field-with-error').find('span.hint--left').hide();
      }
    }
  });
});