define('tevins/components/file-upload', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    store: _ember.default.inject.service(),
    session: _ember.default.inject.service(),

    filesDidChange: function filesDidChange(files) {
      _ember.default.$('.progress-bar').removeClass().addClass('progress-bar progress-bar-striped active');

      var headers = {};
      this.get('session').authorize('authorizer:token', function (key, value) {
        headers[key] = value;
      });

      var uploader = _emberUploader.default.Uploader.create({
        authHeaders: headers,
        url: this.get('url'),
        type: 'POST',
        paramName: this.get('paramName'),

        ajaxSettings: {
          headers: headers
        }
      });

      var self = this;

      uploader.on('progress', function (e) {
        self.sendAction('action', Math.round(e.percent));
        _ember.default.$('.company_info .progress').removeClass('hide');
      });

      uploader.on('didUpload', function () {
        _ember.default.$('.company_info .progress-bar').removeClass('progress-bar-striped active').addClass('progress-bar-success');
        _ember.default.$('.company_info .progress').addClass('hide');
      });

      uploader.on('didError', function () {
        _ember.default.$('.company_info .progress-bar').removeClass('progress-bar-striped active').addClass('progress-bar-danger');
      });

      if (!_ember.default.isEmpty(files)) {
        uploader.upload(files[0]).then(function (data) {
          var model = self.get('company');
          self.get('store').pushPayload(data);

          if (model) {
            model.reload();
          }
        }, function () {
          // Handle failure
        });
      }
    }

  });
});