define('tevins/models/inspection', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    state: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    inspection_type: _emberData.default.attr('string'),
    occupant_type: _emberData.default.attr('string'),
    building_type: _emberData.default.attr('string'),
    inspect_at: _emberData.default.attr('date'),
    water: _emberData.default.attr('number'),
    hot_water: _emberData.default.attr('number'),
    gas: _emberData.default.attr('number'),
    heat: _emberData.default.attr('number'),
    power_1: _emberData.default.attr('number'),
    power_2: _emberData.default.attr('number'),
    power_3: _emberData.default.attr('number'),
    power_4: _emberData.default.attr('number'),
    external_reference: _emberData.default.attr('string'),
    agent: _emberData.default.attr('string'),
    insertedAt: _emberData.default.attr('date'),

    company: _emberData.default.belongsTo('company'),
    participants: _emberData.default.hasMany('participant'),
    pictures: _emberData.default.hasMany('picture')
  });
});