define('tevins/models/participant', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    receive_email: _emberData.default.attr('boolean'),
    signature: _emberData.default.attr('string'),
    inspection: _emberData.default.belongsTo('inspection')
  });
});