define('tevins/routes/dashboard', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_authenticatedRouteMixin.default, {
    session: _ember.default.inject.service(),
    token: _ember.default.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('currentUser', this.get('token.data'));
    }
  });
});