define('tevins/routes/subscriptions', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_authenticatedRouteMixin.default, {
    session: _ember.default.inject.service(),
    token: _ember.default.inject.service(),

    model: function model() {
      return this.store.findAll('subscription-plan');
    },

    renderTemplate: function renderTemplate() {
      this.render('subscriptions', {
        into: 'dashboard'
      });
    }
  });
});