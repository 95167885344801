define('tevins/controllers/users', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    sessionUser: _ember.default.inject.service('session-user'),

    modelName: 'user',
    defaultFields: {
      initials: '',
      email: '',
      company: ''
    },

    modelSorting: ['admin:desc'],
    modelSorted: _ember.default.computed.sort('model', 'modelSorting'),

    modalNew: false,
    spinner: false,
    userAdded: false,

    actions: {
      showModalNew: function showModalNew() {
        this.set('modalNew', true);
        this.set('newModel', this.store.createRecord(this.get('modelName'), this.get('defaultFields')));
      },
      closeModalNew: function closeModalNew() {
        this.set('modalNew', false);

        var newModel = this.get('newModel');
        newModel.rollbackAttributes();
      },
      create: function create() {
        this.set('spinner', true);
        var newModel = this.get('newModel');
        var self = this;

        newModel.set('company', this.get('company'));

        newModel.save().then(function () {
          self.set('modalNew', false);
          self.set('spinner', false);
          self.set('userAdded', true);
          self.send('resetErrors');
        }).catch(function (error) {
          self.send('resetErrors');
          self.set('spinner', false);

          error['errors'].forEach(function (key) {
            var selector = key.source.pointer.split("/").pop();
            var message = key.title;
            _ember.default.$('#' + selector).closest('.field').addClass('field-with-error').append('<span class="hint--left hint--rounded" aria-label="' + message + '"><i class="fa fa-question-circle"></i></span>');
          });
        });
      },
      resetErrors: function resetErrors() {
        _ember.default.$('.field-with-error').removeClass('field-with-error').find('span.hint--left').hide();
      },
      destroy: function destroy(record) {
        var answer = confirm('Weet u zeker dat u deze gebruiker wilt verwijderen?');
        if (answer === true) {
          record.destroyRecord();
        }
      }
    }
  });
});