define("tevins/controllers/index", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Controller.extend({
    session: _ember.default.inject.service(),
    modals: ["signUpModal", "loginModal", "passwordResetModal"],
    passwordResetNotification: false,
    loginSpinner: false,
    signUpSpinner: false,

    actions: {
      toggleModal: function toggleModal(modal) {
        this.send('closeModals');

        this.get(modal) ? this.set(modal, false) : this.set(modal, true);
      },
      closeModals: function closeModals() {
        var self = this;

        this.get('modals').forEach(function (modal_variable) {
          self.set(modal_variable, false);
        });
      },
      register: function register() {

        var self = this;
        var companyName = this.get('register_companyName');
        var identification = this.get('register_email');
        var password = this.get('register_password');
        this.send('resetErrors', 'register');

        if (!companyName) {
          _ember.default.$(".errors-register #register_companyName").closest('.field').addClass('field-with-error').append("<span class=\"hint--left hint--rounded\" aria-label=\"Vul een bedrijfsnaam in\"><i class=\"fa fa-question-circle\"></i></span>");
        } else {
          this.set('signUpSpinner', true);
          var model = this.get('store').createRecord('user', { email: identification, password: password, companyName: companyName, company: '', modalOnboarding: true });

          model.save().then(function () {
            self.set('signUpSpinner', false);
            self.set('signUpModal', false);

            self.get('session').authenticate('authenticator:custom', identification, password);
          }).catch(function (error) {
            self.set('signUpSpinner', false);
            error['errors'].forEach(function (key) {
              var selector = key.source.pointer.split("/").pop();
              var message = key.title;
              _ember.default.$(".errors-register #register_" + selector).closest('.field').addClass('field-with-error').append("<span class=\"hint--left hint--rounded\" aria-label=\"" + message + "\"><i class=\"fa fa-question-circle\"></i></span>");
            });
          });
        }
      },
      authenticate: function authenticate() {
        var _this = this;

        this.set('loginSpinner', true);
        var identification = this.get('login_identification');
        var password = this.get('login_password');
        var self = this;
        this.get('session').authenticate('authenticator:custom', identification, password).catch(function (reason) {
          self.send('resetErrors', 'login');

          _this.set('loginSpinner', false);
          if (reason.error && reason.error === "unauthorized") {
            _ember.default.$(".errors-login #login_password").closest('.field').addClass('field-with-error').append("<span class=\"hint--left hint--rounded\" aria-label=\"Ongeldig wachtwoord\"><i class=\"fa fa-question-circle\"></i></span>");
          } else if (reason.error && reason.error === "Not found") {
            _ember.default.$(".errors-login #login_identification").closest('.field').addClass('field-with-error').append("<span class=\"hint--left hint--rounded\" aria-label=\"E-mailadres niet gevonden\"><i class=\"fa fa-question-circle\"></i></span>");
          } else {
            _ember.default.$(".errors-login #login_identification").closest('.field').addClass('field-with-error').append("<span class=\"hint--left hint--rounded\" aria-label=\"" + (reason.error || reason) + "\"><i class=\"fa fa-question-circle\"></i></span>");
          }
        });
      },
      passwordReset: function passwordReset() {
        this.set('passwordResetSpinner', true);
        var self = this;
        var targetEmail = this.get('password_reset_identification');
        var model = this.store.createRecord('passwordReset', { email: targetEmail });

        model.save().then(function () {
          self.set('passwordResetSpinner', false);
          self.set('passwordResetNotification', "<div class=\"alert alert-success\"><strong>Gelukt!</strong> Wij hebben een tijdelijk wachtwoord verstuurd naar '" + targetEmail + "'</div>");
        }).catch(function () {
          self.set('passwordResetNotification', "<div class=\"alert alert-danger\"><strong>Helaas!</strong> Het e-mailadres '" + targetEmail + "' is niet bij ons bekend </div>");
          self.set('passwordResetSpinner', false);
        });
      },
      resetErrors: function resetErrors(resource) {
        _ember.default.$(".errors-" + resource + " .field-with-error").removeClass('field-with-error').find('span.hint--left').hide();
      }
    }
  });
});