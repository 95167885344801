define('tevins/models/subscription-plan-change', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    company: _emberData.default.belongsTo('company'),
    subscriptionPlanNext: _emberData.default.belongsTo('subscription-plan'),
    subscriptionPlanPrevious: _emberData.default.belongsTo('subscription-plan'),
    payment: _emberData.default.belongsTo('payment')
  });
});