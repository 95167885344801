define('tevins/models/company', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    default: _emberData.default.attr('boolean'),
    initial: _emberData.default.attr('boolean'),
    recurring: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    logoUrl: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    color: _emberData.default.attr('string'),
    interval: _emberData.default.attr('string'),
    subscriptionPlan: _emberData.default.belongsTo('subscription-plan'),
    companyPlans: _emberData.default.hasMany('company-plan'),
    companyPlan: _emberData.default.belongsTo('company-plan')
  });
});