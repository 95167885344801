define('tevins/initializers/link-to-helper-extension', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    _ember.default.LinkComponent.reopen({
      attributeBindings: ['style']
    });
  } // app/initializers/link-to-helper-extension.js

  exports.default = {
    name: 'link-to-helper-extend',
    initialize: initialize
  };
});