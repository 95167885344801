define('tevins/routes/users', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_authenticatedRouteMixin.default, {
    session: _ember.default.inject.service(),
    token: _ember.default.inject.service(),
    sessionUser: _ember.default.inject.service('session-user'),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var self = this;

      this.get('sessionUser').loadCurrentUser().then(function () {
        var user = self.get('sessionUser.user');

        controller.set('company', user.get('company'));
      });
    },
    model: function model() {
      return this.store.findAll('user');
    },


    renderTemplate: function renderTemplate() {
      this.render('users', {
        into: 'dashboard'
      });
    }
  });
});