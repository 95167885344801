define('tevins/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend(_applicationRouteMixin.default, {
    sessionUser: _ember.default.inject.service('session-user'),
    moment: _ember.default.inject.service(),

    beforeModel: function beforeModel() {
      this._loadCurrentUser();
      this.get('moment').setLocale('nl');
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this;

      this._super.apply(this, arguments);
      this._loadCurrentUser().catch(function () {
        return _this.get('session').invalidate();
      });
      this.transitionTo('inspections');
    },
    _loadCurrentUser: function _loadCurrentUser() {
      return this.get('sessionUser').loadCurrentUser();
    }
  });
});