define('tevins/services/session-user', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Service.extend({
    session: _ember.default.inject.service(),
    store: _ember.default.inject.service(),
    token: _ember.default.inject.service(),

    loadCurrentUser: function loadCurrentUser() {
      var _this = this;

      return new _ember.default.RSVP.Promise(function (resolve, reject) {

        var activeUser = _this.get('token.data').user_id;
        if (!_ember.default.isEmpty(activeUser)) {
          return _this.get('store').find('user', activeUser).then(function (user) {
            _this.set('user', user);
            resolve();
          }, reject);
        } else {
          resolve();
        }
      });
    }
  });
});